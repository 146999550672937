@import "variables";
/* CSS Document */

@import url(https://fonts.googleapis.com/css?family=Play:400,700&subset=latin,latin-ext);
* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}

body {
    background-color: #FFF;
    margin: 0;
    font-family: 'Play', Trebuchet Ms, Verdana;
    font-size: 13px;
    position: relative;
    min-height: 100%;
    padding-top: 88px;
    background-image: url(../images/bg-page-header.jpg);
    background-repeat: no-repeat;
    background-position: top center;
    color: $third-color;
    opacity: 0;
    transition: all ease-in 0.2s;
    &.portal{
        background-image: url(../images/shop-new-triggerrun_21.png);
    }
}

h1,h2,h3,h4,h5,h6{
    text-transform: uppercase;
    font-weight: bold;
}

h5{
    font-size: 1rem;
}

.h2, h2 {
    font-size: 1.5rem;
}

.captcha div{
    margin: 0 auto 15px;
}

h4{
    font-size: 16px;
}

a {
    color: $primary-color;
    text-decoration: none;
    a:hover {
        color: $third-color;
    }
}

label{
    font-weight: 700;
    font-size: 15px;
}

.clear {
    clear: both;
}

.float-l {
    float: left !important;
    display: block;
}

.float-r {
    float: right !important;
    display: block;
}

.btn{
    text-transform: uppercase;
    border-radius: 0;
    &.btn-secundary{
        background-color: $secundary-color;
        color: $white;
        &:hover{
            background-color: $secundary-color - 20%;
        }
    }
}

select#lang {
    background-color: #1d1d1d;
    color: #666;
    border: 0;
}

#langBox{
    line-height: 8px;
    padding: 0px 0;
    font-size: 11px;
    option, select{
        text-transform: uppercase;
    }
}

.navbar{
    padding: 0.82em 0;
}

.modal-backdrop{
    background-color: #dadada;
    &.show{
        opacity: 0.8;
    }
}

.modal-content{
    border-radius: 0;
    border: 0;
}

.logged{
    display: none;
}

.about-card{
    .about-card-avatar{}
    
    .about-card-name{
        font-weight: 900;
        color: $secundary-color;
        text-align: center;
        font-size: 18px;
        text-transform: uppercase;
    }

    .about-card-function{
        color: $primary-color;
        text-align: center;
        font-size: 16px;
    }

    .about-card-description{
        text-align: center;
        font-size: 14px;
    }
}

.header {
    height: 86px;
    background-repeat: repeat-x;
    width: 100%;
    position: fixed;
    z-index: 420;
    top: 0;
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color:rgba(240,240,240,0.3);
    background-image: -moz-linear-gradient( 90deg, rgba(145, 145, 145, 0.23922) 0%, rgba(186, 186, 186, 0.41) 30%, rgba(198, 198, 198, 0.46) 39%, rgba(214, 214, 214, 0.58039) 58%, rgba(215, 215, 215, 0.65) 59%, rgba(224, 224, 224, 0.62941) 69%, rgb(232, 232, 232) 79%, rgb(250, 250, 250) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgba(145, 145, 145, 0.23922) 0%, rgba(186, 186, 186, 0.41) 30%, rgba(198, 198, 198, 0.46) 39%, rgba(214, 214, 214, 0.58039) 58%, rgba(215, 215, 215, 0.65) 59%, rgba(224, 224, 224, 0.62941) 69%, rgb(232, 232, 232) 79%, rgb(250, 250, 250) 100%);
    background-image: -ms-linear-gradient( 90deg, rgba(145, 145, 145, 0.23922) 0%, rgba(186, 186, 186, 0.41) 30%, rgba(198, 198, 198, 0.46) 39%, rgba(214, 214, 214, 0.58039) 58%, rgba(215, 215, 215, 0.65) 59%, rgba(224, 224, 224, 0.62941) 69%, rgb(232, 232, 232) 79%, rgb(250, 250, 250) 100%);
    height: 88px;

    &.affix{
        background-image: -moz-linear-gradient(90deg, rgba(145, 145, 145, 0.82) 0%, rgba(186, 186, 186, 0.8) 30%, rgba(198, 198, 198, 0.8) 39%, rgba(214, 214, 214, 0.88039) 58%, rgba(215, 215, 215, 0.85) 59%, rgba(224, 224, 224, 0.82941) 69%, #e8e8e8 79%, #fafafa 100%);
        background-image: -webkit-linear-gradient(90deg, rgba(145, 145, 145, 0.82) 0%, rgba(186, 186, 186, 0.8) 30%, rgba(198, 198, 198, 0.8) 39%, rgba(214, 214, 214, 0.88039) 58%, rgba(215, 215, 215, 0.85) 59%, rgba(224, 224, 224, 0.82941) 69%, #e8e8e8 79%, #fafafa 100%);
        background-image: -ms-linear-gradient(90deg, rgba(145, 145, 145, 0.82) 0%, rgba(186, 186, 186, 0.8) 30%, rgba(198, 198, 198, 0.8) 39%, rgba(214, 214, 214, 0.88039) 58%, rgba(215, 215, 215, 0.85) 59%, rgba(224, 224, 224, 0.82941) 69%, #e8e8e8 79%, #fafafa 100%);
    }

    .bg-primary {
        background-color: transparent !important;
    }

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
    }
    .logo {
        display: block;
        margin-top: 15px;
        padding: 0;
    }
    .header-enterprise {
        background-color: $fourth-color;
        .header-social {
            float: right;
            margin: 0;
            display: block;
            padding: 0;
            margin-top: 1px;
            margin-bottom: 2.5px;
        }
        .header-social li {
            display: inline-block;
            list-style: none;
            margin: 0 0;
            vertical-align: middle;

            a{
                fill: #666;
                svg{
                    display: block;
                    width: 17px;
                }

                &:hover{
                    fill: $primary-color;
                }
            }
        }
    }
    .navbar-collapse{
        width: 100%;
        position: fixed;
        left: 0;
        right: 0;
        background-color: $primary-color;
        height: auto;
        top: 86px;

        li{
            text-transform: uppercase;
            font-size: 18px;
        }

        .nav-item-user{
            text-align: right;
            font-size: 13px;
            line-height: 5px;
            padding-right: 0;
            background-color: rgba($secundary-color, 0.9);
            height: 62px;
            padding-top: 20px;
            color: $white;
            padding-right: 15px;
            padding-left: 15px;
            margin-bottom: 15px;
            span{
                display: block;
            }
            a.nav-link{
                color: #000;
                line-height: 10px;
                display: inline-block;
            }
        }
    
    }


    @media #{$small} {

        .navbar-collapse{
            width: 100%;
            position: static;
            left: 0;
            right: 0;
            background-color: transparent;
            height: auto;
            top: 86px;
            padding-right: 0;
    
            li{
                text-transform: uppercase;
                font-size: 18px;
            }

            .nav-item-user{
                margin-bottom: 0;
            }
        }

        .navbar {
            padding: 0;
        }
        .navbar-dark .navbar-nav .nav-link {
            color: $third-color;
        }
        .menu {
            display: block;
            margin: 0;
            padding: 0;
            float: right;
            li {
                display: inline-block;
                font-size: 18px;
                line-height: 46px;
                list-style: none outside none;
                margin: 0 8px;
                vertical-align: bottom;
                &:last-of-type {
                    margin-right: 0;
                }
            }
            a {
                text-decoration: none;
                color: $primary-color;
                text-transform: uppercase;
                &:hover {
                    color: $third-color;
                }
            }
        }
    }
}

#welcome{
    .img-fluid{
        max-width: 260px;
        margin: 30px auto;
        display: block;
    }

    h1{
        color: $primary-color;
        font-size: 28px;
        text-align: center;
    }

    p{
        font-size: 18px;
        line-height: 26px;
        text-align: center;
    }

    .order-2{
        text-align: center;
    }

    @media #{$small}{

        .img-fluid{
            max-width: 100%;
            margin-top: 15px;
        }

        h1{

            margin: 15px 0 0;
        }

    }

    @media #{$medium}{
        padding-top: 45px;
        .order-2{
            order: 1;
            text-align: left;
        }

        .img-fluid{
            max-width: 300px;
            margin: 60px 0;
            display: inline;
        }

        p{
            text-align: left;
            margin: 30px 0;
        }

        h1{
            text-align: left;
        }
        .order-1{
            order: 2;
            text-align: right;
        }
    }

    @media #{$large}{
        .img-fluid{
            max-width: 400px;
            margin: 27.5px 0;
            display: inline;
        }
    }
}

#games-home{
    padding: 0 0;
    .game-display{
        width: 100%;
        display: block;
    }

    .game-display-item{
       
        .game-display-item-details{

            padding: 15px;
            width: 100%;
            background-color: rgba($white - 5%, 0.6);
            height: 262px;

            h2{
                color: $secundary-color;
                margin-bottom: 0;
            }

            small{
                font-size: 14px;
            }

            strong{
                color: $primary-color;
            }

            p{
                font-size: 16px;
                margin-top: 15px;
                margin-bottom: 15px;
            }

            width: 100%;
                flex: 0 0 100%;
                max-width: 100%;    
                position: relative;
                min-height: 1px;  
                display: flex;                
                padding: 30px;
                text-align: left;
                a{
                    align-self: baseline;                    
                }
        }

            width: 100%;
            flex: 0 0 100%;
            vertical-align: middle;
            display: flex;
            flex-direction: column;
            
            .wrap{
                width: 100%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-self: center;
            }

            .game-display-item-poster{

                height: 262px;
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;    
                position: relative;
                display: flex;      
                min-height: 1px;     
                     img{
                         max-height: 200px;
                         margin: 0 30px;
                         display: inline-block;
                         vertical-align: center;
                         align-self: center;
                     }
                .wrap{
                    padding: 30px;
                }
            }

    }


    @media #{$medium}{
        .game-display{
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        }
        .game-display-item{
            width: 100%;
            flex: 0 0 100%;
            vertical-align: middle;
            display: flex;
            flex-direction: row;
            .wrap{
                width: 100%;
                max-width: 320px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-self: center;
            }

            .game-display-item-poster{

                height: 262px;
                width: 100%;
                flex: 0 0 50%;
                max-width: 50%;    
                position: relative;
                display: flex;      
                min-height: 1px;     
                     img{
                         max-height: 200px;
                         margin: 0 30px;
                         display: inline-block;
                         vertical-align: center;
                         align-self: center;
                     }
                .wrap{
                    padding: 30px;
                }
            }

            

            .game-display-item-details{
                width: 100%;
                flex: 0 0 50%;
                max-width: 50%;    
                position: relative;
                min-height: 1px;  
                display: flex;                
                padding: 30px;
                text-align: right;
                a{
                    align-self: baseline;                    
                }
            }
        }
        div:nth-child(odd).game-display-item{
            .game-display-item-poster{
                order: 1;
                justify-content: flex-end;
                flex-direction: row;
                text-align: right;
            }
            .game-display-item-details{
                order: 2;
                text-align: left;
            }
        }
        div:nth-child(even).game-display-item{
            
            .game-display-item-poster{
                order: 2;
                
            }
            .game-display-item-details{
                order: 1;
                justify-content: flex-end;
                flex-direction: row;
                text-align: right;
                a{
                    align-self: flex-end;
                }
            }
        }
    }
    @media #{$large}{
        .game-display-item{
            .wrap{
                width: 100%;
                max-width: 480px;
            }
        }

    }
    @media #{$extra-large}{
        .game-display-item{
            .wrap{
                width: 100%;
                max-width: 480px;
            }
        }


    }
}
.flickity-button {
    background: transparent;
}

#news{
    margin-bottom: 60px;
}

.news, .modal-news{


    .modal-header{
        flex-direction: column;
        position: relative;
        .close{
            position: absolute;
            top: 7.5px;
            right: 15px;
        }
    }
    

    .news-item{
        
        width: 100%;
        max-width: 350px;
        padding: 0 15px;
        padding-bottom: 30px;
    }
    .news-item-poster{
        width: 100%;
        height: 190px;
        background-size: poster;
        background-position: center;
    }
    .news-item-details{
        background-color: #F9F9F9;
        padding: 15px;
        width: 100%; 
        height: 231px;
        position: relative;
        padding-bottom: 44px;
    }
    .news-item-details-category{
        color: $primary-color;
        font-weight: 700;
        margin-bottom: 7.5px;
        line-height: 15px;
    }
    .news-item-details-title{
        font-size: 1.3em;
        font-weight: 700;
        line-height: 23px;
    }
    .news-item-details-date{
        font-size: 0.8em;
    }

    .news-item-details-highlight{
        font-size: 1.2em;
        margin: 7.5px 0;
        line-height: 23px;
        min-height: 70px;
    }

    .news-item-details-action{
        background-color: $primary-color;
        color: $white;
        font-weight: 700;
        width: 50px;
        line-height: 30px;
        height: 30px;
        text-align: center;
        font-size: 26px;
        text-decoration: none;
        display: inline-block;
        position: absolute;
        bottom: 15px;
        &:hover, &:active, &:visited, &:focus{
            text-decoration: none;
        }
    }

    @media #{$small}{}
    @media #{$medium}{}
    @media #{$large}{}
}


.modal-news{
    font-size: 1.1em;
    background-color: #F7F7F7;
    background-image: url(../images/shop-new-triggerrun_21.png);
    background-repeat: no-repeat;
    background-position: center -267px;

    .modal-header{
        background-color: rgba(255, 255, 255, 0.6);
        background-blend-mode: screen;
    }

    .news-item-details-title{
        color: $secundary-color;
    }

    .news-item-details-highlight{
        background-color: rgba(0, 149, 255, 0.7);
        padding: 15px;
        font-weight: 700;
        margin-left: -16px;
        margin-right: -16px;
        margin-top: -17px;
        background-blend-mode: darken;
        color: #FFF;
    }

    .news-item-details-content{
        font-size: 1.04em;
        padding: 7.5px;
    }
}

.news{
    margin-bottom: 35px;

    .flickity-page-dots{
        bottom: 0;
    }

    &.listAll{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        
        &:after{
            content: " ";
            display: block;
            clear: both;
        }

        .news-item{
            min-width: 280px;
        }
    }
}

footer{
    padding: 30px 0;
    img.img-fluid{
        max-width: 100px;
        display: inline-block;
    }

    a{
        small{
            text-transform: uppercase;
        }
    }
}
.games-amount{
    .games-amount-item{
        .games-amount-item-game{
            height: 132px;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            margin-bottom: 30px;
            -ms-flex-align: center;
            -webkit-align-items: center;
            -webkit-box-align: center;
            padding: 15px;
            align-items: center;

            cursor: pointer;

            img{
                display: block;
                margin: 0 auto;
            }

            &[data-gameid="1"]{

                    background-image: -moz-linear-gradient( -115deg, rgba(227,4,122, 0.20) 0%, rgba(56,53,159, 0.20) 52%, rgba(31,255,158, 0.20) 100%);
                    background-image: -webkit-linear-gradient( -115deg, rgba(227,4,122, 0.20) 0%, rgba(56,53,159, 0.20) 52%, rgba(31,255,158, 0.20) 100%);
                    background-image: -ms-linear-gradient( -115deg, rgba(227,4,122, 0.20) 0%, rgba(56,53,159, 0.20) 52%, rgba(31,255,158, 0.20) 100%);
                    transition: all ease-in 0.2s;
                    &.active, &:hover{
                        background-image: -moz-linear-gradient( -115deg, rgba(227,4,122, 1) 0%, rgba(56,53,159, 1) 52%, rgba(31,255,158, 1) 100%);
                        background-image: -webkit-linear-gradient( -115deg, rgba(227,4,122, 1) 0%, rgba(56,53,159, 1) 52%, rgba(31,255,158, 1) 100%);
                        background-image: -ms-linear-gradient( -115deg, rgba(227,4,122, 1) 0%, rgba(56,53,159, 1) 52%, rgba(31,255,158, 1) 100%);
                        transition: all ease-in 0.2s;
                    }
                            
            }
            &[data-gameid="2"]{


                    background-image: -moz-linear-gradient( -115deg, rgba(142,111,84, 0.20) 0%, rgba(190,161,119, 0.20) 50%, rgba(148,136,99, 0.20) 100%);
                    background-image: -webkit-linear-gradient( -115deg, rgba(142,111,84, 0.20) 0%, rgba(190,161,119, 0.20) 50%, rgba(148,136,99, 0.20) 100%);
                    background-image: -ms-linear-gradient( -115deg, rgba(142,111,84, 0.20) 0%, rgba(190,161,119, 0.20) 50%, rgba(148,136,99, 0.20) 100%);
                    transition: all ease-in 0.2s;
                    &.active, &:hover{
                        background-image: -moz-linear-gradient( -115deg, rgba(142,111,84, 1) 0%, rgba(190,161,119, 1) 50%, rgba(148,136,99, 1) 100%);
                        background-image: -webkit-linear-gradient( -115deg, rgba(142,111,84, 1) 0%, rgba(190,161,119, 1) 50%, rgba(148,136,99, 1) 100%);
                        background-image: -ms-linear-gradient( -115deg, rgba(142,111,84, 1) 0%, rgba(190,161,119, 1) 50%, rgba(148,136,99, 1) 100%);
                        transition: all ease-in 0.2s;
                    }
                  
            }
            &[data-gameid="3"]{
                
                    background-image: -moz-linear-gradient( -115deg, rgba(77,134,212, 0.20) 0%, rgba(0,216,255, 0.20) 97%);
                    background-image: -webkit-linear-gradient( -115deg, rgba(77,134,212, 0.20) 0%, rgba(0,216,255, 0.20) 97%);
                    background-image: -ms-linear-gradient( -115deg, rgba(77,134,212, 0.20) 0%, rgba(0,216,255, 0.20) 97%);
                    transition: all ease-in 0.2s;
                  
                    &.active, &:hover{
                        background-image: -moz-linear-gradient( -115deg, rgba(77,134,212, 1) 0%, rgba(0,216,255, 1) 97%);
                        background-image: -webkit-linear-gradient( -115deg, rgba(77,134,212, 1) 0%, rgba(0,216,255, 1) 97%);
                        background-image: -ms-linear-gradient( -115deg, rgba(77,134,212, 1) 0%, rgba(0,216,255, 1) 97%);
                        transition: all ease-in 0.2s;
                    }
            }
            &[data-gameid="4"]{

                    background-image: -moz-linear-gradient( -115deg, rgba(206,58,33, 0.20) 0%, rgba(255,168,0, 0.20) 97%);
                    background-image: -webkit-linear-gradient( -115deg, rgba(206,58,33, 0.20) 0%, rgba(255,168,0, 0.20) 97%);
                    background-image: -ms-linear-gradient( -115deg, rgba(206,58,33, 0.20) 0%, rgba(255,168,0, 0.20) 97%);
                    transition: all ease-in 0.2s;
                       
                    &.active, &:hover{
                        background-image: -moz-linear-gradient( -115deg, rgba(206,58,33, 1) 0%, rgba(255,168,0, 1) 97%);
                        background-image: -webkit-linear-gradient( -115deg, rgba(206,58,33, 1) 0%, rgba(255,168,0, 1) 97%);
                        background-image: -ms-linear-gradient( -115deg, rgba(206,58,33, 1) 0%, rgba(255,168,0, 1) 97%);
                        transition: all ease-in 0.2s;
                    }
        }
        }
    }
}

.text-center-xs{
    text-align: center;
    @media #{$small}{
        text-align: unset;
    }
}

.text-right-sm{
    text-align: unset;
    @media #{$small}{
        text-align: right;
    }
}

.packages-amount{
    text-align: center;
    .packages-amount-item{
        width: 175px;
        height: 204px;
        position: relative;
        color: $white;
        display: inline-block;
        cursor: pointer;
        
        // + .packages-amount-item{
        //     margin-left: 15px;
        // }
        .caxh-button {
            position: absolute;
            bottom: 0;
            width: 156px;
            left: 10px;
            border: 0;
            background-color: transparent;
            color: $white;
            line-height: 29px;
            cursor: pointer;
        }

        .caxh-pack {
            position: absolute;
            display: block;
            right: 15px;
            top: 56px;
            font-size: 18px;
            color: $white;
            
        }

        .caxh-value {
            position: absolute;
            display: block;
            left: 9px;
            bottom: 35px;
            width: 157px;
            text-align: center;
            color: $white;
            font-weight: 700;
            font-size: 20px;
        }

        &.packages-amount-item-50{
            background-image: url(../images/cash-01.png);
            background-position: top center;
            .caxh-button{
                background-color: #e01f88;
                width: 157px;
                left: 9px;
                &.active{
                    color: #e01f88;
                    background-color: $white;
                    border: 1px solid #e01f88;
                }
            }
            
        }

        &.packages-amount-item-100{
            background-image: url(../images/cash-02.png);
            background-position: top center;
            .caxh-button{
                background-color: #012ce6;
                &.active{
                    color: #012ce6;
                    background-color: $white;
                    border: 1px solid #012ce6;
                }
            }

        }

        &.packages-amount-item-200{
            background-image: url(../images/cash-03.png);
            background-position: top center;
            .caxh-button{
                background-color: #06c8ab;
                &.active{
                    color: #06c8ab;
                    background-color: $white;
                    border: 1px solid #06c8ab;
                }
            }

        }

        &.packages-amount-item-500{
            background-image: url(../images/cash-04.png);
            background-position: top center;
            .caxh-button{
                background-color: #67b80a;
                &.active{
                    color: #67b80a;
                    background-color: $white;
                    border: 1px solid #67b80a;
                }
            }

        }

        &.packages-amount-item-1000{
            background-image: url(../images/cash-05.png);
            background-position: top center;
            .caxh-button{
                background-color: #fd9300;
                &.active{
                    color: #fd9300;
                    background-color: $white;
                    border: 1px solid #fd9300;
                }
            }

        }
        @media #{$small}{

            + .packages-amount-item{
                margin-left: 15px;
            }
        }
        @media #{$medium}{
            + .packages-amount-item{
                margin-left: auto;
            }
                margin: 15px 40px;            

        }
        @media #{$large}{
            margin: 15px 3.5px;            

            + .packages-amount-item{
                margin-left: auto;
            }
        }
        @media #{$extra-large}{
            margin: 15px 37px;            

            + .packages-amount-item{
                margin-left: auto;
            }
        }
    }
}

.primary-color{
    color: $primary-color;
}

.welcomeblock{
    text-align: center;
    @media #{$small} {
        text-align: left;
    }
}

.login2{
    padding: 7.5px;
}
.btn-twitter {
    background-image: url(../images/btn-twitter.png);
    display: block;
    width: 17px;
    height: 17px;
}

.content h2 {
    color: $primary-color;
    font-weight: normal;
    font-weight: 700;
    text-transform: uppercase;
    margin: 5px;
    padding: 0;
}

.btn-twitter:hover {
    background-position: -17px 0;
}

.btn-facebook {
    background-image: url(../images/btn-facebook.png);
    display: block;
    width: 17px;
    height: 17px;
}

.btn-facebook:hover {
    background-position: -17px 0;
}

.btn-gplus {
    background-image: url(../images/btn-googleplus.png);
    background-position: -1px 0;
    display: block;
    width: 16px;
    height: 17px;
}

.btn-gplus:hover {
    background-position: -17px 0;
}

.btn-youtube {
    background-image: url(../images/btn-youtube.png);
    display: block;
    width: 17px;
    height: 17px;
}

.btn-youtube:hover {
    background-position: -17px 0;
}

.login-facebook {
    background-image: url(../images/btn-login-fb.png);
    display: block;
    width: 24px;
    height: 24px;
    margin: 4px 0 0;
}

.login-facebook:hover {
    background-position: -24px 0;
}

.footer {
    background-image: url('../images/bg-footer.png');
    background-position: center 99px;
    background-repeat: no-repeat;
    height: 155px;
    padding-bottom: 24px;
    padding-top: 53px;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.footer .max-width {
    height: 85px;
}

.logo-axion-footer {
    display: block;
    float: left;
    margin: 20px 170px 0 0;
    padding: 0;
}

.menu-footer {
    list-style: none outside none;
    margin: 0;
    padding: 0;
    display: block;
    width: 450px;
    float: left;
}

.menu-footer a {
    color: #444;
    text-decoration: none;
    font-size: 14px;
}

.menu-footer a:hover {
    color: $primary-color;
    text-decoration: none;
}

.menu-footer .title {
    color: $primary-color;
    font-size: 14px;
}

.footer-social {
    clear: both;
    color: #000000 !important;
    display: block;
    float: right;
    list-style: none outside none;
    margin: -68px 6px 0 0;
    padding: 0;
    text-align: right;
}

.menu-footer li {
    float: left;
    padding: 5px 6px;
}

.footer-social>li {
    display: inline-block;
}

.footer-social .title {
    color: $primary-color;
    padding-bottom: 3px;
}
.pass {
    float: left;
    margin-top: 0 !important;
    width: 150px;
}

.login a {
    color: #444;
    text-decoration: none;
    display: block;
    float: left;
}

.login button {
    background: #f6f8f9;
    /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y2ZjhmOSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2U1ZWJlZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUxJSIgc3RvcC1jb2xvcj0iI2Q3ZGVlMyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmNWY3ZjkiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, #f6f8f9 0%, #e5ebee 50%, #d7dee3 51%, #f5f7f9 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f6f8f9), color-stop(50%, #e5ebee), color-stop(51%, #d7dee3), color-stop(100%, #f5f7f9));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #f6f8f9 0%, #e5ebee 50%, #d7dee3 51%, #f5f7f9 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #f6f8f9 0%, #e5ebee 50%, #d7dee3 51%, #f5f7f9 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #f6f8f9 0%, #e5ebee 50%, #d7dee3 51%, #f5f7f9 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #f6f8f9 0%, #e5ebee 50%, #d7dee3 51%, #f5f7f9 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f8f9', endColorstr='#f5f7f9', GradientType=0);
    /* IE6-8 */
    border: 0 none;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.2);
    color: $primary-color;
    cursor: pointer;
    float: right;
    margin-top: 16px;
    padding: 3px 7px 3px;
    width: 119px;
    font-family: 'Play';
    font-size: 14px;
    font-weight: 700;
    background-repeat: repeat-y;
    background-size: 100% 90px;
    background-position: 0 -30px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    text-transform: uppercase;
    transition: all 0.2s linear;
}

.login button:hover {
    background-position: 0 -60px;
    color: #666;
}

.login input {
    border: 0;
    border-radius: 5px 5px 5px 5px;
    padding: 5px 7px;
    color: #444;
    font-family: 'Play';
    width: 266px;
}


.about-card{
    padding: 15px;
    border: 1px $white - 15% solid;
    background-image: url(../images/shop-new-triggerrun_21.png);
    background-size: 700px auto;
    background-position: center top;
    background-repeat: no-repeat;
    height: 100%;
    img{
        margin-bottom: 15px;
    }
}