﻿
$max-extra-small:           "only screen and (max-width: 767px)";

$extra-small:               "only screen and (min-width: 450px)";

$small:                     "only screen and (min-width: 576px)";

$medium:                    "only screen and (min-width: 768px)";

$large:                     "only screen and (min-width: 992px)";

$extra-large:                     "only screen and (min-width: 1200px)";

$primary-color: #0095ff;
$secundary-color: #ff640b;
$third-color: #818181;
$fourth-color: #1d1d1d;
$warning-color: #ffba00;
$info-color: #c5e589;
$white: #FFF;
$black: #FFF;